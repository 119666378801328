<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            交易条款
        </div>
        <div class="vip-content-inner">
            <div class="rich-text">
                <p>交易条款</p>
                <br>
                <p>交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易三发去交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易022 58613872交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款交易条款</p>

            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "registerAndLogin"
}
</script>

<style scoped>

</style>
